<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Lịch sử thay đổi hóa đơn'">
          <template v-slot:preview>
            <!-- Header session -->
            <div class="col-md-12 mb-5">
              <b-form>
                <b-row class="mb-5">
                  <b-col style="padding-left: 0px">
                    <b-input-group>
                      <date-picker
                        placeholder="Từ ngày"
                        class="form-control form-control-sm"
                        :config="dpConfigs.date"
                        v-model="searchFromDay"
                        @dp-change="fetchBillHistory"
                      ></date-picker>
                    </b-input-group>
                  </b-col>
                  <b-col>
                    <b-input-group>
                      <date-picker
                        placeholder="Đến ngày"
                        class="form-control form-control-sm"
                        :config="dpConfigs.date"
                        v-model="searchToDay"
                        @dp-change="fetchBillHistory"
                      ></date-picker>
                    </b-input-group>
                  </b-col>
                  <b-col>
                    <vue-autosuggest
                      v-model="searchStore"
                      :suggestions="filteredStoreOptions"
                      @selected="onSelectedStore"
                      :limit="10"
                      @input="onInputChangeStore"
                      :input-props="{
                        id: 'autosuggest__input',
                        placeholder: 'Nhập cửa hàng'
                      }"
                      :should-render-suggestions="
                        (size, loading) =>
                          size >= 0 && !loading && searchStore !== ''
                      "
                    >
                      <div
                        slot-scope="{ suggestion }"
                        style="display: flex; align-items: center"
                      >
                        <div style="{ display: 'flex', color: 'navyblue'}">
                          {{ suggestion.item.name }}
                        </div>
                      </div>
                    </vue-autosuggest>
                  </b-col>
                  <b-col style="padding-right: 0px"></b-col>
                </b-row>

                <b-row>
                  <b-col style="padding-left: 0px">
                    <b-input placeholder="Người sửa" size="sm"></b-input>
                  </b-col>
                  <b-col>
                    <b-input placeholder="Loại log" size="sm"></b-input>
                  </b-col>
                  <b-col>
                    <b-input
                      v-on:input="fetchBillHistory"
                      placeholder="ID phiếu"
                      size="sm"
                      v-model="searchId"
                    ></b-input>
                  </b-col>
                  <b-col style="padding-right: 0px">
                    <b-input placeholder="Khách hàng" size="sm"></b-input>
                  </b-col>
                </b-row>
              </b-form>
            </div>
            <!-- End of Header session -->
            <!-- Table session -->
            <b-table
              :items="histories"
              :fields="fields"
              class="table-bordered table-hover col-md-12"
              :busy="onLoading"
            >
              <template v-slot:table-busy>
                <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
              </template>
              <template v-slot:cell(actions)="row">
                <div class="d-flex justify-content-center">
                  <b-dropdown size="sm" id="dropdown-left" no-caret right>
                    <template slot="button-content">
                      <i
                        style="font-size: 1rem; padding-right: 0px"
                        class="flaticon2-settings"
                      ></i>
                    </template>
                    <b-dropdown-item @click="viewDetail(row.item)">
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem"
                          class="flaticon-eye icon-color"
                        ></i>
                        &nbsp; Chi tiết
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
            <!-- End of Table session -->

            <!-- Paginate session -->
            <b-row>
              <b-col>
                <p class="mt-3 text-dark" style="font-weight: 500">
                  Tổng số lịch sử thay đổi hóa đơn:
                  {{ totalItems }}
                </p>
              </b-col>
              <b-col>
                <b-pagination-nav
                  class="custom-pagination"
                  v-show="totalPages >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="totalPages"
                  use-router
                  @change="fetchBillHistory"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                  prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
                  next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
                  last-class="page-item-last btn btn-icon btn-sm my-1 "
                  page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
            <!-- End of Paginate session -->
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import localData from '../../../../utils/saveDataToLocal';
import Loader from '@/view/content/Loader.vue';
import { VclTable } from 'vue-content-loading';
import moment from 'moment';
import { removeAccents } from './../../../../utils/common';
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle'
  }
});

export default {
  data() {
    return {
      searchId: '',
      selectedStoreId: '',
      filteredStoreOptions: [],
      storeOptions: [
        {
          data: []
        }
      ],
      searchStore: '',
      searchFromDay: '',
      searchToDay: '',
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true
        }
      },
      histories: [],
      onLoading: false,
      btnCreate: {
        fontWeight: '600!important'
      },
      currentPage: 0,
      totalPages: 0,
      totalItems: 0,
      page: 1,
      search: '',
      linkGen: pageNum => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      fields: [
        {
          key: 'referenceId',
          label: 'ID hóa đơn',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' }
        },
        {
          key: 'type',
          label: 'Kiểu log',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' }
        },
        {
          key: 'orderType',
          label: 'Loại đơn hàng',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' }
        },
        {
          key: 'updatedBy',
          label: 'Người thao tác',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' }
        },
        {
          key: 'createdAt',
          label: 'Thời gian tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' }
        },
        { key: 'actions', label: '' }
      ]
    };
  },
  components: {
    KTCodePreview,
    Loader,
    VclTable,
    datePicker
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Lịch sử', route: 'bill-history' },
      { title: 'Lịch sử thay đổi hóa đơn' }
    ]);
  },
  created() {
    this.fetchStore();
    this.fetchBillHistory();
  },
  methods: {
    fetchStore: async function() {
      await ApiService.setHeader();
      await ApiService.query(`stores/getStores`).then(({ data }) => {
        this.storeOptions[0].data = [];
        data.data.forEach(item => {
          this.storeOptions[0].data.push(item);
        });
      });
    },
    onSelectedStore(option) {
      this.selectedStoreId = option.item.id;
      this.searchStore = option.item.name;
      this.fetchBillHistory();
    },
    onInputChangeStore(text) {
      if (!text) {
        text = '';
      }
      this.searchStore = text;
      const filteredData = this.storeOptions[0].data
        .filter(item => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredStoreOptions = [
        {
          data: filteredData
        }
      ];
    },
    getHistoryTypeName(typeId) {
      switch (typeId) {
        case 1:
          return 'đơn hàng';
        case 2:
          return 'hóa đơn';
        case 3:
          return 'sản phẩm';
        default:
          return '';
      }
    },
    getOrderTypeByName(typeId) {
      switch (typeId) {
        case 1:
          return 'Mua tại quầy';
        case 2:
          return 'Đặt trước';
        case 3:
          return 'Chuyển hàng';
        default:
          return '';
      }
    },
    viewDetail: function(item) {
      this.$router.push({
        name: 'detail-order-history',
        query: { id: item.id }
      });
    },
    fetchBillHistory: async function() {
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      if (!this.search) {
        this.search = '';
      }

      let params = {
        page: this.page,
        pageSize: 10,
        search: this.search,
        //  loại đơn hàng
        type: 1,
        searchFromDay: this.searchFromDay
          ? moment(this.searchFromDay, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '',
        searchToDay: this.searchToDay
          ? moment(this.searchToDay, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '',
        searchStore: this.selectedStoreId,
        searchId: this.searchId
      };

      ApiService.setHeader();
      ApiService.query('/histories', { params }).then(response => {
        this.histories = [];
        let {
          result,
          totalPages,
          currentPage,
          totalItems
        } = response.data.data;

        this.totalPages = totalPages;
        this.currentPage = currentPage;
        this.totalItems = totalItems;

        result.map(item => {
          const history = {
            id: item.id,
            type: item.actionName,
            referenceId: item.referenceId,
            orderType: this.getOrderTypeByName(item.orderType),
            action: item.action,
            description: item.description,
            updatedBy: item.createdByName,
            createdAt: moment(item.createdAt).format('HH:mm - DD/MM/YYYY')
          };
          this.histories.push(history);
        });
      });
    },
    checkPermission: function(condition) {
      return localData.checkPermission(condition);
    }
  },
  computed: {}
};
</script>

<style scoped>
.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}

.icon-color {
  color: #464e5f;
}
</style>

<style>
input::-webkit-input-placeholder {
  color: rgb(190, 190, 200);
}

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5eaee;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
</style>
